import React from "react";
 import Header from "./Header";
 import Sidebar from "./Sidebar";
import Footer from "./Footer";

const Layout = props => {
  return (
    <React.Fragment>
     <div id="layout-wrapper">
        <Header />
        <Sidebar />
        <div className="main-content">{props.children}</div>
        <Footer />
      </div>
    </React.Fragment>
  );
};
export default Layout;
