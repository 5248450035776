// export default {
//   DefaultRoute:"/",
//   Dashboard:"/dashboard",
// }

import React from "react";
import { Redirect } from "react-router-dom";
import Site from "../components/site";
import Dashboard from "../components/dashboard";
import Projects from "../components/projects";
import Mauza from "../components/mauza";
import Files from "../components/files";
import Kpi from "../components/kpi";
import Alarms from "../components/outage";
import OfcBandwidth from "../components/ofcBandwidth";
import Logs from "../components/logs"
const authProtectedRoutes = [
  { path: "/dashboard", component: Dashboard },
  { path: "/site", component: Site },
  { path: "/project", component: Projects },
  { path: "/mauza", component: Mauza },
  { path: "/file", component: Files },
  { path: "/kpi", component: Kpi },
  { path: "/outage", component: Alarms },
   { path: "/ofc-bandwidth", component: OfcBandwidth },
   { path: "/ofc-bandwidth", component: OfcBandwidth },
    {path:"/logs",component:Logs}
];
const publicRoutes = [{ path: "/dashboard", component: Dashboard }];
export { authProtectedRoutes, publicRoutes };