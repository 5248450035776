import React, { useState, useEffect } from 'react'
import MetaTags from "react-meta-tags";
import Breadcrumb from './common/breadCrumb';
import usflogo from "../components/assets/images/usf-logo.svg";
import { Link } from "react-router-dom";
import axios from 'axios';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import excel from "./assets/images/excel.png";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import {side} from '../App';

import {
  Container,
  Row,
  Col,
  ModalHeader,
  ModalBody,
  Modal,
  Card,
  Form,
  Label,
  Spinner,
  Button
} from "reactstrap";
import Dropzone from "react-dropzone";
import DatatableTables from './Table/dataTables';
import keys from './common/keys';
import { apiCall } from './common/helpers';
const saveModal = {
  azmuthSecA: "",
  azmuthSecB: "",
  azmuthSecC: "",
  district: "",
  latitude: 0,
  longitude: 0,
  name: "",
  newUpgradationRepeater: "",
  projectId: "",
  siteId: "",
}
let percentCompleted = null;
function Site(props) {
  const [modal, setmodal] = useState(false);
  const [modal2, setmodal2] = useState(false);
  const [updateSiteModal, setupdateSiteModal] = useState(saveModal)
  const [siteData, setsiteData] = useState(null)
  const [loaderSet, setloaderSet] = useState(false)
  const [nameLength, setnameLength] = useState(false)
  const [selectedFiles, setselectedFiles] = useState([])
  const [progress, setprogress] = useState(0)
  const url = "organization/file/generate-signed-url"
  function handleAcceptedFiles(files) {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    )
    setselectedFiles(files)
  }
  useEffect(async () => {
    setloaderSet(true)
    let siteData = await apiCall('GET', "site")
    setsiteData(siteData.sites);
    setloaderSet(false)
  }, [])

  /**
   * Formats the size
   */
  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }
  const clearFiles = () => {
    setselectedFiles([])
    setprogress(0)
  }
  const uploadFile = async (files) => {
    await setloaderSet(true)
  let modal ={
    type: "site",
      filename: files[0].name,
      contentType: files[0].type
  }
    let siteData = await apiCall('POST', url, modal)
    let objFileName={
      filename:siteData?.filename,
      fileid:siteData?.fileid,
    }
  uploadFileToS3(siteData, files,objFileName)
  }
  const uploadFileToS3 = (presignedPostData, file,fileObj) => {
    console.log("file check", file[0])
    var body = file[0];
 // post the data on the s3 url
    axios.put(presignedPostData.signedRequest, body, {
       onUploadProgress: function (progressEvent) {
        percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
        console.log(percentCompleted)
        PercentValue(percentCompleted)
       }
      ,
      headers: {
        'Content-Type': file[0].type
      }
    }).then(async function (response) {
      console.log(response);
      let responsecheck = await apiCall('POST', "organization/file",fileObj,true)
      if (responsecheck.fileId) {
        setloaderSet(false)
        setmodal(!modal);
       }
      setloaderSet(false)
    })
      .catch(function (error) {
        setloaderSet(false)
        console.log(error);
      });

  };
  const PercentValue = async(value) => {
  await  setprogress(value)
  }
  const onEditClick = async (row) => {
    await setupdateSiteModal(row)
   setnameLength(false)
    setmodal2(!modal2);
    }
  const onHandleChange = (e) => {
    let value=e.target.value;
    const name =e.target.name;
    if(name =="latitude" || name =="longitude"){
     value= parseFloat(value)
    }
    setupdateSiteModal(updateSiteModal => ({ ...updateSiteModal, [e.target.name]: value }))
    if(e.target.name =="district" && value.length >3){
      setnameLength(false)
    }
    else{
      if(name =="district"){  setnameLength(true)}
    }
  }
  const downloadFile = (url) => {
    if(url){
    window.open(url, "_blank");
  }
  };
  const updateSiteModalFunc =async()=>{
    let chk =updateSiteModal;
   setloaderSet(true)
    let modalSiteData ={
      siteId: updateSiteModal.siteId,
      name: updateSiteModal.name,
      // projectName:updateSiteModal.projectName,
      district: updateSiteModal.district,
      latitude: updateSiteModal.latitude,
      longitude: updateSiteModal.longitude,
      newUpgradationRepeater:updateSiteModal.newUpgradationRepeater,
      azmuthSecA:updateSiteModal.azmuthSecA,
      azmuthSecB:updateSiteModal.azmuthSecB,
      azmuthSecC:updateSiteModal.azmuthSecC,
      ms:updateSiteModal.ms
    }
    // if(updateSiteModal.district.length >3){
   let response = await apiCall('PUT', "site",modalSiteData,true)
  setmodal2(!modal2);
  // setloaderSet(false)
  // }
    let siteData = await apiCall('GET', "site")
    setsiteData(siteData.sites);
    setloaderSet(false)
  }
  const loadModalTrue =()=>{
    setmodal(!modal);
    clearFiles();
  }
  return (
    <>
       <Backdrop
        sx={{ color: '#b4e0b4',zIndex:'9999'}}
        open={loaderSet}
        invisible={!loaderSet}
       >
         <CircularProgress color="inherit" />Loading...
        </Backdrop>
    <div className="page-content">
      <MetaTags>
        <title>Site | USF</title>
      </MetaTags>
      <Container fluid>
        {/* Render Breadcrumb */}
        <Breadcrumb
          title="Sites"
          breadcrumbItem="Sites"
        />

        <Row>
        </Row>
      </Container>
      {/* <div>
        <div className="text-sm-end">
          <Button
            onClick={() => {
              setmodal(!modal);
              clearFiles();
            }}
            style={{backgroundColor:"#0b3629",color:"white"}}
            className="font-16 btn-block btn mb-2 w-lg"
          >
            <i className="mdi mdi-plus-circle-outline me-1" />
            Add Site
          </Button>
        </div>
      </div> */}
      <Modal
        size="lg"
        isOpen={modal}
        toggle={() => {
          setmodal(!modal);
        }}
      >
        <ModalHeader
          toggle={() => {
            setmodal(!modal);
            clearFiles()
          }} >
          Upload Site File
        </ModalHeader>
        <ModalBody>
          <form>
            <Row>
              <Col lg={12}>
                <Form>
                  <Dropzone
                    onDrop={acceptedFiles => {
                      handleAcceptedFiles(acceptedFiles)
                    }}
                    accept={[".xlsx", ".csv"]}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <div className="dropzone">
                        <div
                          className="dz-message needsclick mt-2"
                          {...getRootProps()}
                        >
                          <input {...getInputProps()} />
                          <div className="mb-3">
                            <i className="display-4 text-muted bx bxs-cloud-upload" />
                          </div>
                          <h4>Drop file here or click here to upload.</h4>
                        </div>
                      </div>
                    )}
                  </Dropzone>
                  <div className="dropzone-previews mt-3" id="file-previews">
                    {selectedFiles.map((f, i) => {
                      return (
                        <Card
                          className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                          key={i + "-file"}
                        >
                          <div className="p-2">
                            <Row className="align-items-center">
                              <Col className="col-auto">
                                <img
                                  data-dz-thumbnail=""
                                  height="80"
                                  className="avatar-sm rounded bg-light"
                                  // alt={f.name}
                                  src={excel}
                                />
                              </Col>
                              <Col>
                                <Link
                                  to="#"
                                  className="text-muted font-weight-bold"
                                >
                                  {f.name}
                                </Link>
                                <p className="mb-0">
                                  <strong>{f.formattedSize}</strong>
                                </p>
                                <div style={{ width: "40px", height: "40px" }}>
                                </div>
                              </Col>
                              <Col>
                                <div style={{ width: "80px", height: "80px" }}>
                                  {console.log("pppppp", percentCompleted)}
                                  <CircularProgressbar value={progress} active text={`${progress}%`} />;
                                </div>
                              </Col>

                            </Row>
                          </div>
                        </Card>
                      )
                    })}
                  </div>
                </Form>
              </Col>
            </Row>
          </form>
          <Row className='text-end'>
            <Col lg={2}>
            <side.Consumer>
             {(sdata)=>{
              return sdata && sdata.map((row)=>
                {
                  console.log("vvv",row)
                   return <div> {row.name =="Sites" ? 
                  <> <b style={{ fontSize: "17px",color: "#465a3f"}}>Template </b>  <a className="text-success" to="#" target="_blank">
                <i
                  className="fa fa-download font-size-18"
                  id="edittooltip"
                  // onClick={()=>onEditClicks(user)}
                  onClick={() => downloadFile(row.template && row.name =="Sites" ? row.template : null)}
                ></i>
              </a></> : null}

                   </div>
                })
             }}
           </side.Consumer>
            </Col>
            <Col lg={10} >
              <button className="btn mt-2 w-lg" style={{backgroundColor:"#0b3629",color:"white"}} onClick={()=>uploadFile(selectedFiles)} disabled={!selectedFiles.length ? true : progress > 0 ? true : false}>
              Upload
              </button>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
      <Modal
        size="lg"
        isOpen={modal2}
        toggle={() => {
          setmodal2(!modal2);
        }}
        // size="50px"
        scrollable={false}
      >
        <ModalHeader
          toggle={() => {
            setmodal2(!modal2);
          }}
        >
          Update Site
        </ModalHeader>
        <ModalBody>
          <form>
            <Row>
              <Col lg={12}>
                <div className="mb-3">
                  <label htmlFor="siteId">Site ID</label>
                  <input
                    type="text"
                    className="form-control"
                    id="siteId"
                    placeholder="Enter Name"
                    value={updateSiteModal.siteId}
                    name="siteId"
                    disabled
                   onChange={(e) => {
                    onHandleChange(e);
                  }}
                  />
                </div>
               <div className="mb-3 row ">
                  <div className='col-md-3'>
                    <label htmlFor="latitude">Latitude</label>
                    {/* {!updateSiteModal.latitude ? <span class="text-danger">*</span> :null } */}
                    <input
                      type="number"
                      className="form-control"
                      id="latitude"
                      placeholder="Enter Name"
                      maxlength={20}
                      value={updateSiteModal.latitude}
                      name="latitude"
                    onChange={(e) => {
                      onHandleChange(e);
                    }}
                    />
                  </div>
                  <div className='col-md-3'>
                    <label htmlFor="longitude">Longitude</label>
                    {/* {!updateSiteModal.longitude ? <span class="text-danger">*</span> :null } */}
                    <input
                      type="number"
                      className="form-control"
                      id="longitude"
                      placeholder="Enter Name"
                      value={updateSiteModal.longitude}
                      name="longitude"
                      maxlength={20}
                    onChange={(e) => {
                      onHandleChange(e);
                    }}
                    />
                  </div>
                  <div className='col-md-3'>
                    <label htmlFor="district">District</label>
                    {/* {!updateSiteModal.district ? <span class="text-danger">*</span> :null } */}
                    <input
                      type="text"
                      className="form-control"
                      id="district"
                      placeholder="Enter Name"
                      value={updateSiteModal.district}
                      maxlength={28}
                      name="district"
                    onChange={(e) => {
                      onHandleChange(e);
                    }}
                    />
                    {/* {nameLength ? <span class="text-danger">District length too short</span>:null} */}
                    </div>
                    <div className='col-md-3'>
                    <label htmlFor="Ms">Ms</label>
                    {/* {!updateSiteModal.ms ? <span class="text-danger">*</span> :null } */}
                    <input
                      type="text"
                      className="form-control"
                      id="ms"
                      placeholder="Enter ms"
                      value={updateSiteModal.ms}
                      maxlength={28}
                      name="ms"
                    onChange={(e) => {
                      onHandleChange(e);
                    }}
                    />
                    </div>
                </div>
                <div className='row'>
                 <div className="mb-3 col-md-6">
                  <label htmlFor="newUpgradationRepeater">New/Upgradation/Repeater</label>
                  {/* {!updateSiteModal.newUpgradationRepeater ? <span class="text-danger">*</span> :null } */}
                  <input
                    type="text"
                    className="form-control"
                    id="newUpgradationRepeater"
                    placeholder="Enter new/Upgradation/Repeater"
                    value={updateSiteModal.newUpgradationRepeater}
                    maxLength={40}
                    name="newUpgradationRepeater"
                   onChange={(e) => {
                    onHandleChange(e);
                  }}
                  />
                </div>
                <div className="mb-3 col-md-6">
                  <label htmlFor="projectName">Project Name</label>
                  {/* {!updateSiteModal.newUpgradationRepeater ? <span class="text-danger">*</span> :null } */}
                  <input
                    type="text"
                    className="form-control"
                    id="projectName"
                    placeholder="Enter new/Upgradation/Repeater"
                    value={updateSiteModal.projectName}
                    maxLength={40}
                    name="projectName"
                    disabled
                   onChange={(e) => {
                    onHandleChange(e);
                  }}
                  />
                </div>
                </div>
                <div className='row'> 
                <div className="mb-3 col-md-4">
                  <label htmlFor="azmuthSecA">Azimuth Sec-A</label>
                  {/* {!updateSiteModal.azmuthSecA ? <span class="text-danger">*</span> :null } */}
                  <input
                    type="text"
                    className="form-control"
                    id="azmuthSecA"
                   placeholder="Enter Azimtuh Sec-A"
                   maxLength={45}
                    value={updateSiteModal.azmuthSecA}
                    name="azmuthSecA"
                  onChange={(e) => {
                    onHandleChange(e);
                  }}
                  />
                </div>
               <div className="mb-3 col-md-4">
                  <label htmlFor="azmuthSecB">Azimuth Sec-B</label>
                  {/* {!updateSiteModal.azmuthSecB ? <span class="text-danger">*</span> :null } */}
                  <input
                    type="text"
                    className="form-control"
                    id="azmuthSecB"
                    placeholder="Enter Azimtuh Sec-B"
                    value={updateSiteModal.azmuthSecB}
                    maxLength={45}
                     name="azmuthSecB"
                   onChange={(e) => {
                    onHandleChange(e);
                  }}
                  />
                </div>
                <div className="mb-3 col-md-4">
                  <label htmlFor="azmuthSecC">Azimuth Sec-C</label>
                  {/* {!updateSiteModal.azmuthSecC ? <span class="text-danger">*</span> :null } */}
                  <input
                    type="text"
                    className="form-control"
                    id="azmuthSecC"
                    placeholder="Enter Azimtuh Sec-C"
                    maxLength={45}
                    value={updateSiteModal.azmuthSecC}
                    name="azmuthSecC"
                   onChange={(e) => {
                    onHandleChange(e);
                  }}
                  />
                </div>
                </div>
              </Col>
             </Row>
          </form>
          <Row className='text-end'>
            <Col lg={12} >
            {/* disabled={((updateSiteModal.district && updateSiteModal.latitude) && (updateSiteModal.longitude && updateSiteModal.azmuthSecA ) && (updateSiteModal.azmuthSecB && updateSiteModal.azmuthSecC) && (updateSiteModal.newUpgradationRepeater && updateSiteModal.ms)) ? false : true} */}
              <button className="btn w-lg" style={{backgroundColor:"#0b3629",color:"white"}} onClick={()=>updateSiteModalFunc()} >
                Update
              </button>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
      <DatatableTables DataTable={siteData} sites={true} name="Sites" onEditClick={onEditClick} loadModalTrue={loadModalTrue} buttonValue="Add Site" />
    </div> </>
  )
}
export default Site;