import PropTypes from "prop-types";
import React, { useEffect ,useState} from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import axios from "axios";
import SidebarContent from "./SidebarContent";
import { Link } from "react-router-dom";
import usflogo from "../assets/images/usf-logo.svg";

const Sidebar = props => {
  return (
    <React.Fragment>
      <div className="vertical-menu">
        <div className="navbar-brand-box">
          <Link to="/dashboard" className="logo logo-dark">
            <span className="logo-sm">
              <img src={usflogo} alt="" height="35" />
            </span>
            <span className="logo-lg">
              <img src={usflogo} alt="" height="130" />
            </span>
          </Link>

          {/* <Link to="/dashboard" className="logo logo-light">
            <span className="logo-sm">
              <img src={logoLightSvg} alt="" height="22" />
            </span>
            <span className="logo-lg">
              <img src={logoLightPng} alt="" height="19" />
            </span>
          </Link> */}
        </div>
        <div data-simplebar className="h-100">
           <SidebarContent /> 
        </div>
        <div className="sidebar-background"></div>
      </div>
    </React.Fragment>
  );
};


export default Sidebar;
