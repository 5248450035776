import React, { useState, useEffect } from "react";
import DatatableTables from "./Table/dataTables";
import MetaTags from "react-meta-tags";
import Breadcrumb from "./common/breadCrumb";
import { addDays } from "date-fns";
import axios from "axios";
import keys from "./common/keys";
import LoadingOverlay from 'react-loading-overlay';
import Backdrop from '@mui/material/Backdrop';
import {
  convertToObj,
  convertStringToObj,
  apiCall,
  toastifyHelper,
} from "./common/helpers";
import Dropzone from "react-dropzone";
import { Link } from "react-router-dom";
import Select from "react-select";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Spinner,
  Container,
  CardImg,
  CardText,
  CardHeader,
  Label,
} from "reactstrap";
import excel from "./assets/images/excel.png";
import CircularProgress from '@mui/material/CircularProgress';
// import KpiList from './kpiList';
import KpiList from "./kpiList";
var percentCompleted = null;
function Kpi(props) {
  const [selectedGroup, setselectedGroup] = useState(null);
  const [selectedGroupVendor, setselectedGroupVendor] = useState(null);
  const [selectedGroupTechnologies, setselectedGroupTechnologies] =
    useState(null);
  const [operator, setoperator] = useState("");
  const url = "organization/file/generate-signed-url";
  const [selectedFiles, setselectedFiles] = useState([]);
  const [apiRes, setapiRes] = useState([]);
  const [setloader, setsetloader] = useState(false);
  const [size, setsize] = useState(false);
  const [Vendors, setVendors] = useState([]);
  const [todoItems, settodoItems] = useState([]);
  const [progress, setprogress] = useState(0);
  const [vendor, setvendor] = useState("");
  const [newArray, setnewArray] = useState([]);
  const [item, setitem] = useState({});
  const [technology, settechnology] = useState("");
  const [idforProgress, setidforProgress] = useState(null);
  const [technologies, settechnologies] = useState([]);
  function handleSelectGroup(selectedGroup) {
    let value = selectedGroup.value;
    setselectedGroup(selectedGroup);
    setselectedGroupVendor(null);
    setvendor("");
    setselectedGroupTechnologies(null);
    setoperator(value);
    let vendorOfOpretor = Object.keys(apiRes[value]);
    var vendorData = convertToObj(vendorOfOpretor);
    setVendors(vendorData);
    settechnology("");
  }
  
  function handleSelectGroupVendor(selectedGroup) {
    let value = selectedGroup.value;
    setselectedGroupVendor(selectedGroup);
    setvendor(value);
    let techOfVendor = Object.keys(apiRes[operator][value]);
    var techData = convertToObj(techOfVendor);
    settechnologies(techData);
  }
  function handleSelectGroupTechnology(selectedGroup) {
    let value = selectedGroup.value;
    setselectedGroupTechnologies(selectedGroup);
    settechnology(value);
  }
  useEffect(async () => {
    const responsecheck = await apiCall("GET", "organization/file/mapper", null);
   setapiRes(responsecheck.mappersMeta)
    // let operator = Object.keys(apiRes)
    // var data = convertToObj(operator);
    // let vendorOfOpretor =Object.keys(apiRes["telenor"])
    // let kpi =Object.keys(apiRes["telenor"]["huaweii"])
    // console.log(operator)
    // console.log("vOperator",vendorOfOpretor)
    // console.log("kpi",kpi)
  }, []);
  let operators = Object.keys(apiRes);
  var data = convertToObj(operators);

  function handleAcceptedFiles(files) {
    files.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    );
    setselectedFiles(files);
    let chk =files[0].name
    let afterDot = chk.substr(chk.indexOf('.') + 1);
    if(files[0].size < 31457280 && afterDot == "xlsx"){
      setsize(false)
    }
  }
  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }
  const addKpi = () => {
    let chk =selectedFiles[0].name
    let afterDot = chk.substr(chk.indexOf('.') + 1);
     if(selectedFiles[0].size > 100000000 && afterDot == "xlsx"){
      setsize(true)
    } else{
    let itemList = todoItems;
    const item = {
      id: todoItems.length + 1,
      operator: operator,
      vendor: vendor,
      kpi: technology,
      filename: selectedFiles[0].name,
      contentType: selectedFiles[0].type,
      allFiles: [...selectedFiles],
    };
    itemList = [...todoItems, item];

    setitem(item);
    settodoItems(itemList);
    setselectedGroup(null);
    setselectedGroupVendor(null);
    setselectedGroupTechnologies(null);
    setoperator("");
    setselectedFiles([]);
    // setprogress(0)
    setsize(false)
  }
  };
  const deleteCard = async (e) => {
     let id = e.target.id;
   todoItems.splice(id, 1)
    const filtered = todoItems.filter(function (item) {
      return item.id !== id;
    });
   await settodoItems(filtered);
  };
  const causeWait = (row) =>
    new Promise(async (resolve, reject) => {
      try {
        let modal = {
          type: "kpi",
          filename: row.filename,
          contentType: row.contentType,
          operator: row.operator,
          vendor: row.vendor,
          kpi: row.kpi,
        };

        // const token = localStorage.getItem(keys.localStorage.access_token)
        const token = sessionStorage.getItem(keys.localStorage.access_token)
        const API_URL = process.env.REACT_APP_API_URL || "https://api-analytics.usf.softoo-dev.com/"
        let options = {
          method: "POST",
          url: API_URL + url,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer" + " " + token,
          },
          data: modal,
        };
        await axios
          .request(options)
          .then(function (response) {
            const kpiData = response.data.data;
            let objFileName = {
              filename: kpiData?.filename,
              fileid: kpiData?.fileid,
            };
           return uploadFileToS3(kpiData, row.allFiles, objFileName,row);
          })
          .catch(function (error) {
            if (error.response.status == 401 || error.response.status == 403) {
              window.open(keys.loginUrl.logninPage, "_self");
            }
            toastifyHelper(
              "error",
              error.response.data.message || "something went wrong"
            );
          });
          resolve();
      } catch (error) {
        reject(error);
      }
    });
  const uploadFiles = async () => {
    setsetloader(true)
     const sateArr = [...todoItems];
    const copyArr = [...sateArr];
    let newArr=[];
     for (let index = 0; index < copyArr.length; index++) {
      const element = copyArr[index];
      try {
          setidforProgress(element.id)
         const chk =await causeWait(element);
        //  const filtereds = todoItems.filter(function (item) {
        //   return item.id !== element.id;
        // });
        newArr.push(element)
          } catch (error) {
        console.error(error);
       setsetloader(false)
      }

    }
    toastifyHelper("success","File processing started successfully")
    settodoItems([])
   setsetloader(false)
  };
 const uploadFileToS3 =async (presignedPostData, file, fileObj,row) => {
    var body = file[0];
  // post the data on the s3 url
   await axios.put(presignedPostData.signedRequest, body, {
         onUploadProgress: function (progressEvent) {
          percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
           PercentValue(percentCompleted);
        },
        headers: {
          "Content-Type": file[0].type,
        },
      })
      .then(async function (response) {
       const responsecheck = await apiCall("POST", "organization/file", fileObj);
         return responsecheck;
      })
      .catch(function (error) {
         console.log(error);
      });
  };
  const PercentValue = async (value) => {
    await setprogress(value);
   
  };
  return (
  //   <LoadingOverlay
  // active={setloader}
  //  spinner={<Spinner />}
  // text='  Loading ...'
  // styles={{
  //       overlay: (base) => ({
  //         ...base,
  //         zIndex:'9999'
  //       })
  //     }}
  // >
  <>
    <Backdrop
        sx={{ color: '#b4e0b4',zIndex:'9999'}}
        open={setloader}
        invisible={!setloader}
       >
         <CircularProgress color="inherit" />Loading...
        </Backdrop>
    <div className="page-content">
      <MetaTags>
        <title>Kpi | USF</title>
      </MetaTags>
      <Container fluid>
        {/* Render Breadcrumb */}
        <Breadcrumb title="Kpi" breadcrumbItem="Kpi" />
      </Container>
      <Row>
        <Col lg="12">
          <Card>
            <CardBody>
              <form>
                <Row>
                  <Col lg="6">
                    <div className="mb-3">
                      <Label>Operator</Label>
                      {operator ? null : <span class="text-danger">*</span>}
                      <Select
                        value={selectedGroup}
                        onChange={(e) => handleSelectGroup(e)}
                        options={data}
                        classNamePrefix="select2-selection"
                      />
                    </div>
                    <div className="mb-3">
                      <Label>Vendor</Label>
                      {vendor ? null : <span class="text-danger">*</span>}
                      <Select
                        value={selectedGroupVendor}
                        onChange={(e) => handleSelectGroupVendor(e)}
                        isDisabled={operator ? false : true}
                        options={Vendors}
                        classNamePrefix="select2-selection"
                      />
                    </div>
                    <div className="mb-3">
                      <Label>Technologies</Label>
                      {technology ? null : <span class="text-danger">*</span>}
                      <Select
                        value={selectedGroupTechnologies}
                        onChange={(e) => handleSelectGroupTechnology(e)}
                        isDisabled={vendor ? false : true}
                        options={technologies}
                        classNamePrefix="select2-selection"
                      />
                    </div>
                  </Col>
                  <Col lg="6">
                    <Dropzone
                      onDrop={(acceptedFiles) => {
                        handleAcceptedFiles(acceptedFiles);
                      }}
                      accept={[".xlsx", ".csv"]}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <div className="dropzone">
                          <div
                            className="dz-message needsclick mt-2"
                            {...getRootProps()}
                          >
                            <input {...getInputProps()} />
                            <div className="mb-3">
                              <i className="display-4 text-muted bx bxs-cloud-upload" />
                            </div>
                            <h4>Drop file here or click here to upload.</h4>
                          </div>
                        </div>
                      )}
                    </Dropzone>
                    <div className="dropzone-previews mt-3" id="file-previews">
                      {selectedFiles.map((f, i) => {
                        return (
                          <Card
                            className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                            key={i + "-file"}
                          >
                            <div className="p-2">
                              <Row className="align-items-center">
                                <Col className="col-auto">
                                  <img
                                    data-dz-thumbnail=""
                                    height="80"
                                    className="avatar-sm rounded bg-light"
                                    // alt={f.name}
                                    src={excel}
                                  />
                                </Col>
                                <Col>
                                  <Link
                                    to="#"
                                    className="text-muted font-weight-bold"
                                  >
                                    {f.name}
                                  </Link>
                                  <p className="mb-0">
                                    <strong>{f.formattedSize}</strong>
                                  </p>
                                 {size ? <p className="mb-0 text-danger">
                                    <strong>Xlsx File size should be less than 30mb</strong>
                                  </p> :null}
                                  <div
                                    style={{ width: "40px", height: "40px" }}
                                  ></div>
                                </Col>
                              </Row>
                            </div>
                          </Card>
                        );
                      })}
                    </div>
                  </Col>
                </Row>
              </form>
              <div className="col p-4">
                <button
                  onClick={() => addKpi()}
                  type="button"
                  className="btn text-center"
                  style={{backgroundColor:"#0b3629",color:"white"}}
                  disabled={
                    operator && vendor && selectedFiles.length && technology
                      ? false
                      : true
                  }
                >
                  <i className="fa fa-plus-circle" aria-hidden="true" /> Add file to card
                </button>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    <KpiList
        deleteCard={deleteCard}
        todoItems={todoItems}
        fileData={selectedFiles}
        progress={progress}
        ids={idforProgress}
      />
      <div style={{ textAlign: "center" }}>
        <button
          type="button"
          className="btn w-lg"
          style={{backgroundColor:"#0b3629",color:"white"}}
          disabled={todoItems.length ? false : true}
          onClick={() => uploadFiles()}
        >
          Upload
        </button>
      </div>
    </div>
    {/* </Backdrop> */}
    </>
  );
}
export default Kpi;
