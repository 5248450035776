import React, { useState, useEffect } from 'react'
import MetaTags from "react-meta-tags";
import Breadcrumb from './common/breadCrumb';
import usflogo from "../components/assets/images/usf-logo.svg";
import { Link } from "react-router-dom";
import { CircularProgressbar } from 'react-circular-progressbar';
import {
  Container,
  Row,
  Col,
  ModalHeader,
  ModalBody,
  Modal,
  Card,
  Form,
  Spinner,
  Button
} from "reactstrap";
import axios from 'axios';
import excel from "./assets/images/excel.png";
import Dropzone from "react-dropzone";
import DatatableTables from './Table/dataTables';
import { apiCall } from './common/helpers';
import LoadingOverlay from 'react-loading-overlay';
import {side} from "../App"
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader, { Circles } from 'react-loader-spinner';
const fileData={
  type: "",
  filename: "",
  contentType: ""
}
const saveModal={
  latitude: "",
  longitude: "",
  mauzaId: "",
  name: "",
} 

var percentCompleted = null;
function Mauza (props) {
  const [modal, setmodal] = useState(false);
  const [modal2, setmodal2] = useState(false);
  const [mauzaData, setmauzaData] = useState(null)
  const [uploadModal, setuploadModal] = useState(fileData)
  const [loaderSet, setloaderSet] = useState(false)
  const [progress, setprogress] = useState(0)
  // const [fileName, setfileName] = useState(objFileName)
  const [nameLength, setnameLength] = useState(false)
  const [selectedFiles, setselectedFiles] = useState([])
  const [specialChar, setspecialChar] = useState(false);
  const [updateMauzaModal, setupdateMauzaModal] = useState(saveModal)
  const url = "organization/file/generate-signed-url"
  function handleAcceptedFiles(files) {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    )
    setselectedFiles(files)
  }
  useEffect(async () => {
    setloaderSet(true)
    let mauzaData = await apiCall('GET', "mauza")
    setmauzaData(mauzaData.mauzas);
    setloaderSet(false)
  }, [])
  /**
   * Formats the size
   */
  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }
  const uploadFile = async (files) => {
    await setloaderSet(true)
    let modal ={
      type: "mauza",
        filename: files[0].name,
        contentType: files[0].type
    }
    let mauzaData = await apiCall('POST', url, modal)
  let objFileName={
    filename:mauzaData?.filename,
    fileid:mauzaData?.fileid,
  }
 uploadFileToS3(mauzaData, selectedFiles,objFileName)
  }
  const uploadFileToS3 = (presignedPostData, file,fileObj) => {
   console.log("file check", file[0])
    var body = file[0];
  axios.put(presignedPostData.signedRequest, body, {
     onUploadProgress: function (progressEvent) {
        percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
        console.log(percentCompleted)
        PercentValue(percentCompleted)
      } , headers: {
        'Content-Type': file[0].type
      }
    }).then(async function (response) {
      console.log("resss",response);
      let responsecheck = await apiCall('POST', "organization/file",fileObj,true)
      console.log("resss",responsecheck);
      setloaderSet(false)
      setmodal(!modal);
    })
      .catch(function (error) {
        console.log(error);
        setloaderSet(false)
      });
  };
  const PercentValue = (value) => {
    setprogress(value)
  }
  const onEditClick =async (row) => {
    setspecialChar(false)
    await setupdateMauzaModal(row)
     // let opData = convertStringToObj(row.operator)
     // let catData = convertStringToObj(row.category)
     // setselectedGroupCategory(catData)
     // setselectedGroup(opData)
     // setsaveModal(row)
     setnameLength(false)
     setmodal2(!modal2);
    // setupdateSiteModal(row)
     // seteditModal(true)
     
 }
  const clearFiles=()=>{
   setselectedFiles([])
   setprogress(0)
  }
//   #pageloader
// {
//   background: rgba( 255, 255, 255, 0.8 );
//   display: none;
//   height: 100%;
//   position: fixed;
//   width: 100%;
//   z-index: 9999;
// }

// #pageloader img
// {
//   left: 50%;
//   margin-left: -32px;
//   margin-top: -32px;
//   position: absolute;
//   top: 50%;
// }
//  const onHandleChange = (e) => {
//     debugger
//     let value=e.target.value;
//     setupdateMauzaModal(updateMauzaModal => ({ ...updateMauzaModal, [e.target.name]: e.target.value }))
//     if(value.length >3){
//       setnameLength(false)
//     }
//     else{
//       setnameLength(true)
//     }
//   }
  const onHandleChange = (e) => {
    let value=e.target.value;
    const name =e.target.name;
    if(name =="latitude" || name =="longitude"){
     value= parseFloat(value)
    }
    setupdateMauzaModal(updateMauzaModal => ({ ...updateMauzaModal, [e.target.name]: value }))
   if(name =="name"){
    let  format = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;  
    let newvalue =(format.test(value))
    if(newvalue == true){
      setspecialChar(true)
   }
   else if(newvalue == false){
     setspecialChar(false)
   }
  }
    // if(e.target.name =="name" && value.length >3){
    //   setnameLength(false)
    // }
    // else{
    //   if(name =="name"){  
        
    //   }
    // }
  }
  const updateMauzaModalFunc =async()=>{
    await setloaderSet(true)
    let chk =updateMauzaModal;
    let modalMauzaData ={
      mauzaId: updateMauzaModal.mauzaId,
      name: updateMauzaModal.name,
      latitude: updateMauzaModal.latitude,
      longitude: updateMauzaModal.longitude,
      siteId:updateMauzaModal.siteId
    }
  let  format = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;  
let value =(format.test(updateMauzaModal.name));
if(value == true){
  setspecialChar(true)
}
    else{
   let response = await apiCall("PUT", "mauza",modalMauzaData,true)
     setmodal2(!modal2);
     setspecialChar(true)
     }
    // else{
    //   if(updateMauzaModal.name.length <3){setnameLength(true)}
    //  }

    let mauzaData = await apiCall('GET', "mauza")
    setmauzaData(mauzaData.mauzas);
     setloaderSet(false)
  }
  const downloadFile = (url) => {
    if(url){
    window.open(url, "_blank");
  }}
  const loadModalTrue =()=>{
    setmodal(!modal);
    clearFiles();
  }
    return (
      <>
       <Backdrop
        sx={{ color: '#b4e0b4',zIndex:'9999'}}
        open={loaderSet}
        invisible={!loaderSet}
       >
         <CircularProgress color="inherit" />Loading...
        </Backdrop>
        <div className="page-content">
           <MetaTags>
          <title>Mauza | USF</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumb
             title="Mauzas"
            breadcrumbItem="Mauzas"
          />
         <Row>
              </Row>
              </Container>
              {/* <div>
              <div className="text-sm-end" style={{marginRight:"10px"}}>
        <Button
          onClick={() => {
            setmodal(!modal);
            clearFiles();
          }}
          style={{backgroundColor:"#0b3629",color:"white"}}
          className="popup-form btn mb-2 w-lg"
        >
          <i className="mdi mdi-plus-circle-outline me-1" />
          Add Mauza
        </Button>
      </div>
      </div> */}
      <Modal
        size="lg"
        isOpen={modal}
        toggle={() => {
          setmodal(!modal);
        }}
      >
        <ModalHeader
          toggle={() => {
            setmodal(!modal);
            clearFiles()
          }}
        >
          Upload Mauza File
        </ModalHeader>
        <ModalBody>
          <form>
            <Row>
              <Col lg={12}>
              <Form>
                    <Dropzone
                      onDrop={acceptedFiles => {
                        handleAcceptedFiles(acceptedFiles)
                      }}
                      accept={[".xlsx",".csv"]}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <div className="dropzone">
                          <div
                            className="dz-message needsclick mt-2"
                            {...getRootProps()}
                          >
                            <input {...getInputProps()} />
                            <div className="mb-3">
                              <i className="display-4 text-muted bx bxs-cloud-upload" />
                            </div>
                            <h4>Drop file here or click here to upload.</h4>
                          </div>
                        </div>
                      )}
                    </Dropzone>
                    <div className="dropzone-previews mt-3" id="file-previews">
                      {selectedFiles.map((f, i) => {
                        return (
                          <Card
                            className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                            key={i + "-file"}
                          >
                            <div className="p-2">
                              <Row className="align-items-center">
                                <Col className="col-auto">
                                  <img
                                    data-dz-thumbnail=""
                                    height="80"
                                    className="avatar-sm rounded bg-light"
                                    alt={f.name}
                                    src={excel}
                                  />
                                </Col>
                                <Col>
                                  <Link
                                    to="#"
                                    className="text-muted font-weight-bold"
                                  >
                                    {f.name}
                                  </Link>
                                  <p className="mb-0">
                                    <strong>{f.formattedSize}</strong>
                                  </p>
                                </Col>
                                <Col>
                                <div style={{ width: "80px", height: "80px" }}>
                                  {console.log("pppppp", percentCompleted)}
                                  <CircularProgressbar value={progress} active text={`${progress}%`} />;
                                </div>
                              </Col>
                              </Row>
                            </div>
                          </Card>
                        )
                      })}
                    </div>
                  </Form>
              </Col>
              
             
            </Row>
          </form>
          <Row className='text-end'>
          <Col lg={2}>
            <side.Consumer>
             {(sdata)=>{
              return sdata && sdata.map((row)=>
                {
                 
                   return <div> {row.name =="Mauzas" ? 
                  <> <b style={{ fontSize: "17px",color: "#465a3f"}}>Template </b>  <a className="text-success" to="#" target="_blank">
                <i
                  className="fa fa-download font-size-18"
                  id="edittooltip"
                  // onClick={()=>onEditClicks(user)}
                  onClick={() => downloadFile(row.template && row.name =="Mauzas" ? row.template : null)}
                ></i>
              </a></> : null}

                   </div>
                })
             }}
           </side.Consumer>
            </Col>
            <Col lg={10} >

              {/* <button className="btn btn-danger" style={{marginRight:"5px"}} onClick={clearFiles}>
                Clear
              </button> */}
              <button className="btn" style={{backgroundColor:"#0b3629",color:"white"}} onClick={()=>uploadFile(selectedFiles)} disabled={!selectedFiles.length ? true : progress > 0 ? true : false}>
                Upload
              </button>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
      <Modal
        size="lg"
        isOpen={modal2}
        toggle={() => {
          setmodal2(!modal2);
        }}
      >
        <ModalHeader
          toggle={() => {
            setmodal2(!modal2);
          }}
        >
          Update Mauza
        </ModalHeader>
        <ModalBody>
          <form>
            <Row>
              <Col lg={12}>
              {/* <div className="mb-3">
                  <label htmlFor="name">Mauza ID</label>
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    placeholder="Enter Name"
                     value={updateMauzaModal.mauzaId.slice(24)}
                    name="name"
                    disabled
                  />
                </div> */}
              <div className="mb-3">
                  <label htmlFor="siteId">Site ID</label>{!updateMauzaModal.siteId ? <span class="text-danger">*</span> :null }
                  <input
                    type="text"
                    className="form-control"
                    id="siteId"
                    placeholder="Enter siteId"
                     value={updateMauzaModal.siteId}
                    name="siteId"
                    onChange={(e) => {
                      onHandleChange(e);
                    }}
                  />
                </div>
              <div className="mb-3">
                  <label htmlFor="name">Mauza name</label> {!updateMauzaModal.name ? <span class="text-danger">*</span> :null }
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    maxlength={29}
                    placeholder="Enter Name"
                     value={updateMauzaModal.name}
                    name="name"
                    onChange={(e) => {
                      onHandleChange(e);
                    }}
                  />
                  {specialChar ? <span class="text-danger">Special Characters not Allowed</span>: null}
                  {/* {nameLength ? <span class="text-danger">Name length too short</span>:null} */}
                </div>
                {/* <div className="mb-3">
                  <label htmlFor="name">District</label>
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    placeholder="Enter Name"
                     value={updateSiteModal.district}
                    name="name"
                    // onChange={(e) => {
                    //   onHandleChange(e);
                    // }}
                  />
                </div> */}
                <div className="mb-3 row d-flex">
                  <div className='col-md-6'>
                  <label htmlFor="latitude">Latitude</label> {!updateMauzaModal.latitude ? <span class="text-danger">*</span> :null }
                  <input
                    type="number"
                    className="form-control"
                    id="latitude"
                    placeholder="Enter latitude"
                    maxLength={20}
                     value={updateMauzaModal.latitude}
                    name="latitude"
                    onChange={(e) => {
                      onHandleChange(e);
                    }}
                  />
                  </div>
                  <div className="mb-3 col-md-6">
                  <label htmlFor="longitude">Longitude</label> {!updateMauzaModal.longitude ? <span class="text-danger">*</span> :null }
                    <input
                      type="number"
                      className="form-control"
                      id="longitude"
                      placeholder="Enter Name"
                      max="999"
                      value={updateMauzaModal.longitude}
                      name="longitude"
                    onChange={(e) => {
                      onHandleChange(e);
                    }}
                    />
                </div>
                </div>
                 </Col>
            </Row>
          </form>
          <Row className='text-end'>
            <Col lg={12} >
            {/* disabled={((updateMauzaModal.name && updateMauzaModal.latitude) && (updateMauzaModal.siteId && updateMauzaModal.longitude) ) ? false : true} */}
              <button className="btn" style={{backgroundColor:"#0b3629",color:"white"}} onClick={()=>updateMauzaModalFunc()} disabled={!specialChar && (updateMauzaModal.name && updateMauzaModal.latitude) && (updateMauzaModal.longitude) ? false : true} >
                Update
              </button>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
      <DatatableTables name="Mauzas" DataTable={mauzaData} mauzas={true} onEditClick={onEditClick} loadModalTrue={loadModalTrue} buttonValue="Add Mauza"/>
     </div> </>
    )
}

export default Mauza;