import {
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    Spinner,
    Container,
    CardImg,
    CardText,
    CardHeader,
    Label,
  } from "reactstrap";
  import {
    convertToObj,
    convertStringToObj,
    toastifyHelper,
  } from "./common/helpers";
  import Backdrop from '@mui/material/Backdrop';
import { CircularProgressbar } from 'react-circular-progressbar';
  import excel from "./assets/images/excel.png";
  import CircularProgress from '@mui/material/CircularProgress';
  import React, { useState, useEffect } from "react";
  import MetaTags from "react-meta-tags";
  import Breadcrumb from "./common/breadCrumb";
  import Dropzone from "react-dropzone";
// import "bootstrap-daterangepicker/daterangepicker.css";
  import { Link } from "react-router-dom";
//   import { MonthSelector } from 'react-bootstrap-month-selector';
  import Switch from '@mui/material/Switch';
  import Select from "react-select";  
import DateRangePicker from "react-bootstrap-daterangepicker";
import DatePicker from "react-datepicker";
import "../components/layout/customDatePickerWidth.css";
import "react-datepicker/dist/react-datepicker.css";
import axios from 'axios';

import { apiCall } from './common/helpers';
let percentCompleted = null;

function OfcBandwidth(props) {
  const [selectedFiles, setselectedFiles] = useState([]);
  const [apiRes, setapiRes] = useState([]);
  const [operatorValue, setoperatorValue] = useState(null);
  const [startDate, setStartDate] = useState(new Date());
  const [selectedGroup, setselectedGroup] = useState(null);
  const [dateformated, setdateformated] = useState(null);
  const [loaderSet, setloaderSet] = useState(false)
  const [progress, setprogress] = useState(0)
  const url = "organization/file/generate-signed-url"
  console.log(startDate)
//   /organization/file/mapper/ofc
//   const handleChange = (event) => {
//       let value =event.target.checked;

//       if(value == true){
//         setbreadValue("Alarm")
//       }else{
//           setbreadValue("OFC Bandwidth")
//       }
//     setChecked(event.target.checked);

//   };
  function handleSelectGroup(selectedGroup) {
    let value = selectedGroup.value
    setselectedGroup(selectedGroup);
    setoperatorValue(value)
    // operatorValue
    // setsaveModal(saveObj => ({ ...saveObj, operator: value }))
  }
useEffect(async () => {
    // setloaderSet(true)
    let operators = await apiCall('GET', "organization/file/mapper/ofc")
    setapiRes(operators.mappersMeta)
    // setloaderSet(false)
    let Date=startDate.toISOString()
    let ssDate=Date.slice(0,7);
    setdateformated(ssDate)
  }, [])
  let operators = Object.keys(apiRes);
  var data = convertToObj(operators);
const uploadFile = async (files) => {
    await setloaderSet(true)
  let modal ={
    type: "ofc",
    date:dateformated,
    operator:operatorValue,
      filename: files[0].name,
      contentType: files[0].type
  }
    let siteData = await apiCall('POST', url, modal)
    let objFileName={
      filename:siteData?.filename,
      fileid:siteData?.fileid,      
    }
  uploadFileToS3(siteData, files,objFileName)
  
  }
  const funcDate =(date)=>{
      setStartDate(date)
    let ssDate= date.getFullYear()+ '-' + ("00" + (date.getMonth() + 1)).slice(-2);
    setdateformated(ssDate)
  }
  const uploadFileToS3 = (presignedPostData, file,fileObj) => {
    console.log("file check", file[0])
    var body = file[0];
 // post the data on the s3 url
    axios.put(presignedPostData.signedRequest, body, {
       onUploadProgress: function (progressEvent) {
        percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
        console.log(percentCompleted)
        PercentValue(percentCompleted)
       }
      ,
      headers: {
        'Content-Type': file[0].type
      }
    }).then(async function (response) {
      console.log(response);
      let responsecheck = await apiCall('POST', "organization/file",fileObj,true)
      if (responsecheck.fileId) {
        setloaderSet(false)
        setselectedFiles([])
     }
      setloaderSet(false)
    })
      .catch(function (error) {
        setloaderSet(false)
        console.log(error);
        setselectedFiles([])
      });
      
  };
  const PercentValue = async(value) => {
  await  setprogress(value)
  }
    function handleAcceptedFiles(files) {
        files.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
            formattedSize: formatBytes(file.size),
          })
        );
        setselectedFiles(files);
        
      }
      function formatBytes(bytes, decimals = 2) {
        if (bytes === 0) return "0 Bytes";
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
      }
  return <>
  <Backdrop
      sx={{ color: '#b4e0b4',zIndex:'9999'}}
      open={loaderSet}
      invisible={!loaderSet}
     >
       <CircularProgress color="inherit" />Loading...
      </Backdrop>
  <div className="page-content">
    <MetaTags>
      <title>Office Bandwidth | USF</title>
    </MetaTags>
    <Container fluid>
      {/* Render Breadcrumb */}
      <Breadcrumb title="Ofc bandwidth" breadcrumbItem="Ofc bandwidth" />
    </Container>
    <Row>
      <Col lg="12">
        <Card>
        {/* <Switch
      checked={checked}
      onChange={handleChange}
      inputProps={{ 'aria-label': 'controlled' }}
    />  */}
      <CardBody>
            <form>
              <Row>
                <Col lg="12">
                  <div className="mb-3">
                    <Label>Operator</Label>
                    {/* {operator ? null : <span class="text-danger">*</span>} */}
                    <Select
                      value={selectedGroup}
                       onChange={(e) => handleSelectGroup(e)}
                      options={data}
                      classNamePrefix="select2-selection"
                    />
                  </div>
                  <div className="mb-3">
                    <Label>Date</Label>
                    {/* {vendor ? null : <span class="text-danger">*</span>} */}
                    <DatePicker
      selected={startDate}
      onChange={(date) => funcDate(date)}
      dateFormat="MM/yyyy"
      showMonthYearPicker
     width="5px"
    />
   </div>
                </Col>
                <Col lg="12">
                  <Dropzone
                    onDrop={(acceptedFiles) => {
                      handleAcceptedFiles(acceptedFiles);
                    }}
                    accept={[".xlsx", ".csv"]}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <div className="dropzone">
                        <div
                          className="dz-message needsclick mt-2"
                          {...getRootProps()}
                        >
                          <input {...getInputProps()} />
                          <div className="mb-3">
                            <i className="display-4 text-muted bx bxs-cloud-upload" />
                          </div>
                          <h4>Drop file here or click here to upload.</h4>
                        </div>
                      </div>
                    )}
                  </Dropzone>
                  <div className="dropzone-previews mt-3" id="file-previews">
                    {selectedFiles.map((f, i) => {
                      return (
                        <Card
                          className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                          key={i + "-file"}
                        >
                          <div className="p-2">
                            <Row className="align-items-center">
                              <Col className="col-auto">
                                <img
                                  data-dz-thumbnail=""
                                  height="80"
                                  className="avatar-sm rounded bg-light"
                                  // alt={f.name}
                                  src={excel}
                                />
                              </Col>
                              <Col>
                                <Link
                                  to="#"
                                  className="text-muted font-weight-bold"
                                >
                                  {f.name}
                                </Link>
                                <p className="mb-0">
                                  <strong>{f.formattedSize}</strong>
                                </p>
                                <div
                                  style={{ width: "40px", height: "40px" }}
                                ></div>
                              </Col>
                              <Col>
                                <div style={{ width: "80px", height: "80px" }}>
                                  {console.log("pppppp", percentCompleted)}
                                  <CircularProgressbar value={progress} active text={`${progress}%`} />;
                                </div>
                              </Col>
                            </Row>
                          </div>
                        </Card>
                      );
                    })}
                  </div>
                </Col>
              </Row>
            </form>
            {/* <div className="col p-4">
              <button
                onClick={() => addKpi()}
                type="button"
                className="btn text-center"
                style={{backgroundColor:"#0b3629",color:"white"}}
                disabled={
                  operator && vendor && selectedFiles.length && technology
                    ? false
                    : true
                }
              >
                <i className="fa fa-plus-circle" aria-hidden="true" /> Add file to card
              </button>
            </div> */}
          </CardBody>
          <div style={{ textAlign: "center" }}>
      <button
        type="button"
        className="btn w-lg mb-2"
        style={{backgroundColor:"#0b3629",color:"white"}}
     disabled={selectedFiles.length && operatorValue ? false : true}
        onClick={() => uploadFile(selectedFiles)}
      >
        Upload
      </button>
    </div>
        </Card>
      </Col>
    </Row>
    {/* <div style={{ textAlign: "center" }}>
      <button
        type="button"
        className="btn w-lg"
        style={{backgroundColor:"#0b3629",color:"white"}}
        // disabled={todoItems.length ? false : true}
        onClick={() => uploadFile(selectedFiles)}
      >
        Upload
      </button>
    </div> */}
  </div>
  {/* </Backdrop> */}
  </>;
}
export default OfcBandwidth;