import PropTypes from "prop-types";
 import React, { useEffect,useRef ,useState} from "react";
import axios from "axios";
// //Import Scrollbar
import SimpleBar from "simplebar-react";

// MetisMenu
import MetisMenu from "metismenujs";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import Dashboard from "../dashboard";
import { side } from "../../App";

const SidebarContent = props => {
   const ref = useRef();
  // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  useEffect(() => {
    const pathName = props.location.pathname;

    
    const initMenu = () => {
      new MetisMenu("#side-menu");
      let matchingMenuItem = null;
      const ul = document.getElementById("side-menu");
      const items = ul.getElementsByTagName("a");
      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i];
          break;
        }
      }
      };
    initMenu();
  }, [props.location.pathname]);

  useEffect(() => {
    ref.current.recalculate();
  });

  // function scrollElement(item) {
  //   if (item) {
  //     const currentPosition = item.offsetTop;
  //     if (currentPosition > window.innerHeight) {
  //       ref.current.getScrollElement().scrollTop = currentPosition - 300;
  //     }
  //   }
  // }

  // function activateParentDropdown(item) {
  //   item.classList.add("active");
  //   const parent = item.parentElement;
  //   const parent2El = parent.childNodes[1];
  //   if (parent2El && parent2El.id !== "side-menu") {
  //     parent2El.classList.add("mm-show");
  //   }

  //   if (parent) {
  //     parent.classList.add("mm-active");
  //     const parent2 = parent.parentElement;

  //     if (parent2) {
  //       parent2.classList.add("mm-show"); // ul tag

  //       const parent3 = parent2.parentElement; // li tag

  //       if (parent3) {
  //         parent3.classList.add("mm-active"); // li
  //         parent3.childNodes[0].classList.add("mm-active"); //a
  //         const parent4 = parent3.parentElement; // ul
  //         if (parent4) {
  //           parent4.classList.add("mm-show"); // ul
  //           const parent5 = parent4.parentElement;
  //           if (parent5) {
  //             parent5.classList.add("mm-show"); // li
  //             parent5.childNodes[0].classList.add("mm-active"); // a tag
  //           }
  //         }
  //       }
  //     }
  //     scrollElement(item);
  //     return false;
  //   }
  //   scrollElement(item);
  //   return false;
  // }

  return (
    <>
      <SimpleBar className="h-100" ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li className="menu-title d-flex newClassForLi">   </li>
            <side.Consumer>
             {(sdata)=>{
              return sdata && sdata.map((row)=>
                {
                   return <li className="newClassForLi sideNew newClassForLi">
                   <Link to={{pathname:row.path, state:row  }} className="d-flex" >
                     <i className={row.icon}></i>
                     <span> {row.name} </span>
                   </Link>
                 </li>
                })
             }}
           </side.Consumer>
         </ul>
        </div>
      </SimpleBar>
    </>
  );
};
SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter((SidebarContent));
