import './App.css';
import Layout from './components/layout/layout';
import './components/assets/scss/theme.scss';
import { authProtectedRoutes, publicRoutes } from "./routes";
import Authmiddleware from "./routes/route";
import { Switch, BrowserRouter as Router, Route } from "react-router-dom";
import keys from './components/common/keys';
import Dashboard from './components/dashboard';
import axios from 'axios';
import React, { createContext, useEffect, useState } from "react";
import { apiCall } from './components/common/helpers';
export const side = createContext();
export const dashboard = createContext();
const App = props => {
  const [data, setdata] = useState(null)
  useEffect(() => {
 let token =sessionStorage.getItem(keys.localStorage.access_token)
 // let token =localStorage.getItem(keys.localStorage.access_token)
  const API_URL = process.env.REACT_APP_API_URL || 'https://api-analytics.usf.softoo-dev.com/';
  if(token){
    const options = {
      method: 'GET',
      url: API_URL + 'organization/entities',
      headers: { 'Content-Type': 'application/json', Authorization: 'Bearer'+" "+token}
    };
 axios.request(options).then(function (response) {
      setdata(response.data.data.entities);
    }).catch(function (error) {
      window.open(keys.loginUrl.logninPage, "_self");
    });
  }
  else{
    window.open(keys.loginUrl.logninPage, "_self");
  }
  }, [])
 return (
    <>
      <Router>
        <Switch>
          <side.Provider value={data}>
            {authProtectedRoutes.map((route, idx) => (
              <Authmiddleware
                path={route.path}
                layout={Layout}
                component={route.component}
                key={idx}
                isAuthProtected={false}
                exact
              />
            ))}
          </side.Provider>
        </Switch>
      </Router>
    </>
  );
}

export default App;
