import React, { useState, useEffect } from "react"
import PropTypes from 'prop-types'
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu
} from "reactstrap"
import keys from "./keys"
import user1 from "../assets/images/users/profile.png"
import { apiCall } from "./helpers"
import { Link } from "react-router-dom";

const ProfileMenu = props => {
  const [menu, setMenu] = useState(false)
  const [username, setusername] = useState("User")
  const [LogsData, setLogsData] = useState(null)
  useEffect(() => {
    let token =sessionStorage.getItem(keys.localStorage.access_token)
    if (token) {
    async function fetchData() {
      // You can await here
      let response = await apiCall('GET', "profile")
          setLogsData(response.user)
       setusername(response.user?.user_name || 'User')
    }
    fetchData();
  }
    else {
      window.open(keys.loginUrl.logninPage, "_self");
    }
  }, [])
  const clearAuthData = () => {
     sessionStorage.removeItem(keys.localStorage.access_token);
    window.open(keys.loginUrl.logninPage, "_self");
  }
  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item "
          id="page-header-user-dropdown"
          tag="button"
        >
          <img
            className="rounded-circle header-profile-user"
            src={user1}
            alt="Header Avatar"
          />
          <span className="d-none d-xl-inline-block ms-2 me-1">{username}</span>
          <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <a href={keys.loginUrl.logninPage} onClick={() => clearAuthData()} target="_self" className="dropdown-item">
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
            <span> Logout  </span>
           </a>
          {LogsData?.isAdmin ?
         <Link to={{pathname:"/logs"  }} className="dropdown-item" >
          <i className="bx bx-layer font-size-16 align-middle me-1 text-danger" />
            <span> User Activities </span>
        </Link>
          :null }
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  )
}

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any
}


export default (ProfileMenu)
