import React from "react";
import '@material/mwc-linear-progress';
import {
  Card,
  CardBody,
  CardTitle,
   CardText,
  CardHeader,
  Progress,
} from "reactstrap";
import { CircularProgressbar } from "react-circular-progressbar";
import excel from "./assets/images/excel.png";
const KpiList = (props) => {
  let chk = props.progress/100;
  return (
    <div>
      {props.todoItems &&
        props.todoItems.map((row, index) => (
          <Card outline color="success" className="border">
            <CardHeader className="bg-transparent">
              <h5 className="my-0 text-success">
                <i className="mdi mdi-check-all me-3" />
                <i
                  class="fa fa-trash text-danger"
                  onClick={props.deleteCard}
                  id={index}
                  style={{
                    fontSize: "25px",
                    float: "right",
                    cursor: "pointer",
                  }}
                  aria-hidden="true"
                ></i>
              </h5>
            </CardHeader>
            <CardBody>
              <CardTitle className="mt-0">
                <b>Operator : </b>
                {row.operator} , <b> Vendor : </b>
                {row.vendor} , <b> Technology : </b>
                {row.kpi}
              </CardTitle>
              <CardText>
                <img src={excel} height="80" /> {row.filename}
                {props.ids == row.id ? (
                 <>
                 <div
                    style={{ width: "80px", height: "80px", float: "right" }}
                  >
                    {" "}
                    <CircularProgressbar
                      value={props.progress}
                      active
                      text={`${props.progress}%`}
                      background={true}
                      styles={{
                        path: {
                          // Path color
                          stroke:'#0b3629'
                        },
                        text: {
                          // Text color
                          fill: '#0b3629',
                          // Text size
                          fontSize: '16px',
                        },
                        background: {
                          fill: "#ffffff",
                          textColor: '#ffffff',
                          trailColor: '#0b3629',
                          pathColor: `rgba(62, 152, 199, ${props.progress / 100})`
                        },
                     }
                    }
                    />
                  </div>
                  {/* <mwc-linear-progress progress={chk} buffer={chk +0.2}></mwc-linear-progress> */}
                  </>
                ) : null}{" "}
                ;
              </CardText>
            </CardBody>
          </Card>
        ))}
    </div>
  );
};
export default KpiList;
