import React ,{useState , useEffect}from 'react'
import Breadcrumb from './common/breadCrumb';
import { apiCall } from './common/helpers';
import { Container } from "reactstrap";
import MetaTags from "react-meta-tags";
import DatatableTables from './Table/dataTables';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
 function Logs() {
     const [dataLogs, setdataLogs] = useState(null)
     const [loaderSet, setloaderSet] = useState(false)
    useEffect(() => {
        async function fetchData() {
          // You can await here
          setloaderSet(true)
          let response = await apiCall('GET', "access-log")
          setdataLogs(response.logs)
          setloaderSet(false)
        }
        fetchData();
      }, [])
  return (
    <>
       <Backdrop
        sx={{ color: '#b4e0b4',zIndex:'9999'}}
        open={loaderSet}
        invisible={!loaderSet}
       >
         <CircularProgress color="inherit" />Loading...
        </Backdrop>
      
        <div className="page-content">
        <MetaTags>
       <title>Logs | USF</title>
     </MetaTags>
     <Container fluid>
       {/* Render Breadcrumb */}
       <Breadcrumb
          title="Logs"
         breadcrumbItem="Logs"
       />
        </Container>
        </div>
        <DatatableTables name="Logs" DataTable={dataLogs} logs={true} />
    
     </>
  )
}
export default Logs