import React, { useState, useRef, memo, useEffect } from 'react'
import {
  Row,
  Col,
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  CardTitle,
  Button,
} from 'reactstrap'
import axios from 'axios'
import keys from '../common/keys'
import 'react-date-range/dist/styles.css' // main style file
import 'react-date-range/dist/theme/default.css' // theme css file
// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next'
import { DateRange } from 'react-date-range'
import {
  convertToObj,
  convertStringToObj,
  apiCall,
  toastifyHelper,
} from '../common/helpers'
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from 'react-bootstrap-table2-paginator'
import DateRangePicker from 'react-bootstrap-daterangepicker'

// import 'bootstrap/dist/css/bootstrap.css';
// import 'bootstrap-daterangepicker/daterangepicker.css';

import { addDays } from 'date-fns'
import { Link } from 'react-router-dom'
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css'
import Loader, { Circles } from 'react-loader-spinner'
import PropagateLoader from 'react-spinners/PropagateLoader'
// import "bootstrap/dist/css/bootstrap.css";
import 'bootstrap-daterangepicker/daterangepicker.css'
// import DateRangePicker from '@mui/lab/DateRangePicker';
import TextField from '@mui/material/TextField'
import Box from '@mui/material/Box'
// import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import ToolkitProvider, {
  Search,
} from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min'
// import { DateRangePicker } from "react-date-range";
//Import Breadcrumb
// import Breadcrumbs from "../../components/Common/Breadcrumb"
import '../Table/dataTables.scss'
import SweetAlert from 'react-bootstrap-sweetalert'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
function areEqual(prevProps, nextProps) {
  if (prevProps !== nextProps) {
    // document.getElementById('search-bar-0').value = '',
  }
  /*
  return true if passing nextProps to render would return
  the same result as passing prevProps to render,
  otherwise return false
  */
  return prevProps.DataTable == nextProps.DataTable
}
const DatatableTables = (props) => {
  // useEffect((props) => {

  // }, [props.runAgain]);
  //   const Modal = React.memo(
  //   props => {...},
  //   (prevProps, nextProps) => prevProps.show === nextProps.show
  // );
  const [showDatePicker, setshowDatePicker] = useState(false)
  const [message, setmessage] = useState('')
  var node = useRef()
  const onPaginationPageChange = (page) => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page)
    }
  }
  const [startDate, setstartDate] = useState(new Date())
  const [modal, setmodal] = useState(false)
  const [endDate, setendDate] = useState(new Date())
  const [value, setValue] = useState([null, null])
  const [basic, setbasic] = useState(false)
  // const [state, setState] = useState([
  //   {
  //     startDate: new Date(),
  //     endDate: addDays(new Date(), 7),
  //     key: 'selection'
  //   }
  // ]);

  const showPopup = (row) => {
    props.openMessageModal(row.message)
    // setmessage(row.message);
    // setbasic(true);
  }

  const downloadFile = (url) => {
    window.open(url, '_blank')
  }

  const handleDownloadFile = (downloadUrl) => {
    const url = 'organization/file/generate-url'
    const token = sessionStorage.getItem(keys.localStorage.access_token)
    const API_URL =
      process.env.REACT_APP_API_URL ||
      'https://api-analytics.usf.softoo-dev.com/'
    let options = {
      method: 'GET',
      url: `${API_URL}${url}?key=${downloadUrl}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer' + ' ' + token,
      },
    }
    axios
      .request(options)
      .then(function (response) {
        const downlaodUrl = response.data.data.objectUrl
        downloadFile(downlaodUrl)
      })
      .catch(function (error) {
        if (error.response.status == 401 || error.response.status == 403) {
          window.open(keys.loginUrl.logninPage, '_self')
        }
        toastifyHelper(
          'error',
          error.response.data.message || 'something went wrong',
        )
      })
  }
  // console.log("sssss",state)
  const columns = props.sites
    ? [
        // {
        //   dataField: indexedDB,
        //   text: '#',
        //   sort: true,
        //   headerStyle: { backgroundColor: '  #44866e' ,color:'white'}
        // },
        {
          dataField: 'projectName',
          text: 'Project Name',
          sort: true,
          headerStyle: { backgroundColor: '  #44866e', color: 'white' },
        },
        {
          dataField: 'siteId',
          text: 'Site ID',
          sort: true,
          headerStyle: { backgroundColor: '  #44866e', color: 'white' },
        },
        {
          dataField: 'district',
          text: 'District',
          sort: true,
          headerStyle: { backgroundColor: '  #44866e', color: 'white' },
        },
        {
          dataField: 'newUpgradationRepeater',
          text: 'New Upgradation Repeater',
          headerStyle: { backgroundColor: '  #44866e', color: 'white' },
          sort: true,
        },
        {
          // dataField: "menu",
          isDummyField: true,
          editable: false,
          text: 'Action',
          headerStyle: { backgroundColor: '  #44866e', color: 'white' },
          // eslint-disable-next-line react/display-name
          formatter: (cellContent, user) => (
            <div className="d-flex gap-3">
              <Link className="text-success" to="#">
                <i
                  className="mdi mdi-pencil font-size-18"
                  id="edittooltip"
                  // onClick={()=>onEditClicks(user)}
                  onClick={() => handleUserClickSite(user)}
                ></i>
              </Link>
            </div>
          ),
        },
      ]
    : props.logs
    ? [
        {
          dataField: 'operation',
          text: 'Operation',
          headerStyle: { backgroundColor: '#44866e', color: 'white' },
          sort: true,
        },
        {
          dataField: 'email',
          text: 'Email',
          sort: true,
          headerStyle: { backgroundColor: '#44866e', color: 'white' },
        },
        {
          dataField: 'resource',
          text: 'Resource',
          sort: true,
          headerStyle: { backgroundColor: '#44866e', color: 'white' },
        },
        {
          dataField: 'pk',
          text: 'PK',
          sort: true,
          headerStyle: { backgroundColor: '#44866e', color: 'white' },
        },
        {
          dataField: 'datetime',
          text: 'Date & Time',
          sort: true,
          headerStyle: { backgroundColor: '#44866e', color: 'white' },
        },
      ]
    : props.mauzas
    ? [
        {
          dataField: 'name',
          text: 'Name',
          headerStyle: { backgroundColor: '#44866e', color: 'white' },
          sort: true,
        },
        {
          dataField: 'siteId',
          text: 'Site ID',
          sort: true,
          headerStyle: { backgroundColor: '#44866e', color: 'white' },
        },
        {
          // dataField: "menu",
          isDummyField: true,
          editable: false,
          headerStyle: { backgroundColor: '#44866e', color: 'white' },
          text: 'Action',
          // eslint-disable-next-line react/display-name
          formatter: (cellContent, user) => (
            <div className="d-flex gap-3">
              <Link className="text-success" to="#">
                <i
                  className="mdi mdi-pencil font-size-18"
                  id="edittooltip"
                  // onClick={()=>onEditClicks(user)}
                  onClick={() => handleUserClickSite(user)}
                ></i>
              </Link>
            </div>
          ),
        },
      ]
    : props.files
    ? [
        {
          dataField: 'filename',
          text: 'File Name',
          sort: true,
          headerStyle: { backgroundColor: '  #44866e', color: 'white' },
        },
        {
          dataField: 'fileType',
          text: 'File Type',
          sort: true,
          headerStyle: { backgroundColor: '  #44866e', color: 'white' },
        },
        {
          dataField: 'createdDate',
          text: 'Created Date',
          sort: true,
          headerStyle: { backgroundColor: '  #44866e', color: 'white' },
          formatter: (cellContent, user) => {
            const date = new Date(user.createdDate)
            const formattedDate = `${date.toDateString()} ${date.toTimeString()}`
            return (
              <div className="d-flex gap-3">
                <span>{date.toLocaleString()}</span>
              </div>
            )
          },
        },
        {
          dataField: 'message',
          text: 'Message',
          sort: true,
          events: {
            onClick: (e, column, columnIndex, row, rowIndex) => {
              showPopup(row)
            },
          },
          headerStyle: { backgroundColor: '#44866e', color: 'white' },
          formatter: (cellContent, user) => (
            <div className="d-flex gap-3">
              {user.message ? (
                <>
                  {' '}
                  <span
                    style={{
                      width: '160px',
                      whitespace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      color: 'blue',
                      textDecoration: 'underline',
                    }}
                    // onClick={()=>setmodal(true)}
                  >
                    {user.message}
                  </span>
                  <i class="fas fa-angle-double-right"></i>{' '}
                </>
              ) : (
                '--'
              )}
            </div>
          ),
        },
        {
          dataField: 'startDate',
          text: 'Start Date',
          sort: true,
          headerStyle: { backgroundColor: '  #44866e', color: 'white' },
        },
        {
          dataField: 'endDate',
          text: 'End Date',
          sort: true,
          headerStyle: { backgroundColor: '  #44866e', color: 'white' },
        },
        {
          dataField: 'status',
          text: 'Status',
          sort: true,
          headerStyle: { backgroundColor: '  #44866e', color: 'white' },
          formatter: (cellContent, user) => (
            <div className="d-flex gap-3">
              <span
                className={
                  user.status == 'succeeded'
                    ? 'text-success'
                    : user.status == 'failed'
                    ? 'text-danger'
                    : user.status == 'uploaded'
                    ? 'text-primary'
                    : user.status == 'processing'
                    ? 'text-warning'
                    : user.status == 'timedout'
                    ? 'text-secondary'
                    : null
                }
              >
                {user.status}
              </span>
            </div>
          ),
        },
        {
          // dataField: "menu",
          isDummyField: true,
          editable: false,
          headerStyle: { backgroundColor: '  #44866e', color: 'white' },
          text: 'Action',
          // eslint-disable-next-line react/display-name
          formatter: (cellContent, user) => (
            <div className="d-flex gap-3">
              <a className="text-success" to="#" target="_blank">
                <i
                  className="fa fa-download font-size-18"
                  id="edittooltip"
                  // onClick={()=>onEditClicks(user)}
                  onClick={() => {
                    handleDownloadFile(user.downlaodUrl)
                  }}
                ></i>
              </a>
            </div>
          ),
        },
        //,{
        //     dataField: 'azmuthSecA',
        //     text: 'AzmuthSecA',
        //     sort: true
        //   },{
        //     dataField: 'azmuthSecB',
        //     text: 'AzmuthSecB',
        //     sort: true
        //   },{
        //     dataField: 'azmuthSecC',
        //     text: 'AzmuthSecC',
        //     sort: true
        //   }
      ]
    : [
        // {
        // dataField: 'projectId',
        // text: 'Id',
        // sort: true,
        // headerStyle: { backgroundColor: '  #44866e' ,color:'white'},
        // },
        {
          dataField: 'name',
          text: 'Name',
          headerStyle: { backgroundColor: '  #44866e', color: 'white' },
          sort: true,
        },
        {
          dataField: 'operator',
          text: 'Operator',
          headerStyle: { backgroundColor: '  #44866e', color: 'white' },
          sort: true,
        },
        {
          dataField: 'category',
          text: 'Category',
          headerStyle: { backgroundColor: '  #44866e', color: 'white' },
          sort: true,
        },
        {
          // dataField: "menu",
          isDummyField: true,
          editable: false,
          headerStyle: { backgroundColor: '  #44866e', color: 'white' },
          text: 'Action',
          // eslint-disable-next-line react/display-name
          formatter: (cellContent, user) => (
            <div className="d-flex gap-3">
              <Link className="text-success" to="#">
                <i
                  className="mdi mdi-pencil font-size-18"
                  id="edittooltip"
                  // onClick={()=>onEditClicks(user)}
                  onClick={() => handleUserClick(user)}
                ></i>
              </Link>
            </div>
          ),
        },
      ]
  // Table Data
  const DataTable = props.DataTable ? [...props?.DataTable] : []
  const defaultSorted = [
    {
      dataField: 'id',
      order: 'asc',
    },
  ]
  const pageOptions = {
    sizePerPage: 10,
    totalSize: DataTable.length, // replace later with size(customers),
    custom: true,
  }
  // Custom Pagination Toggle
  const sizePerPageList = [
    { text: '5', value: 5 },
    { text: '10', value: 10 },
    { text: '15', value: 15 },
    { text: '20', value: 20 },
    { text: '25', value: 25 },
    { text: 'All', value: DataTable.length },
  ]

  //   Select All Button operation
  const selectRow = {
    mode: 'checkbox',
  }

  const { SearchBar } = Search
  const showDatePickerFunc = (date) => {
    setshowDatePicker(!date)
  }
  const chkFunc = async (fDate, lastDate) => {
    await props.datePicker(fDate, lastDate)
    // setValue([null, null]);
  }
  const emptyDataMessage = () => {
    // setTimeout(function(){
    return (
      <div className="d-center">
        <b>No Record found</b>
      </div>
    )
    //  }, 3000);
    // return (
    //   <div className="d-center">
    //     <PropagateLoader
    //       color="  #44866e"
    //       loading={true}
    //       css={{ marginLeft: "100px" }}
    //       size={15}
    //     />
    //   </div>
    // );
  }
  //   #44866e
  const handleUserClick = (arg) => {
    const user = arg
    props.onEditClick(user)
  }
  const handleUserClickSite = (arg) => {
    const user = arg
    props.onEditClick(user)
  }
  const handleEvent = (event, picker) => {}
  const handleCallback = (start, end, label) => {
    setstartDate(start._d)
    setendDate(end._d)
  }
  const Apply = (event, picker) => {}
  const keyField = 'id'
  return (
    <React.Fragment>
      <div className="container-fluid">
        <Row>
          <Col className="col-12">
            <Card>
              <CardBody>
                <PaginationProvider
                  pagination={paginationFactory(pageOptions)}
                  keyField={keyField}
                  columns={columns}
                  data={DataTable.length ? DataTable : []}
                >
                  {({ paginationProps, paginationTableProps }) => (
                    <ToolkitProvider
                      keyField={keyField}
                      columns={columns}
                      data={DataTable.length ? DataTable : []}
                      search
                    >
                      {(toolkitProps) => (
                        <React.Fragment>
                          {props.logs ? null : (
                            <Row>
                              {/* <Col md="1" className="h4">{props.name}</Col> */}
                              {props.files ? (
                                <>
                                  <Col
                                    style={
                                      showDatePicker
                                        ? {
                                            backgroundColor: 'rgb(248 248 248)',
                                          }
                                        : null
                                    }
                                    className="d-flex col-md-4 col-sm-12 mb-2"
                                  >
                                    <DateRangePicker
                                      initialSettings={{
                                        startDate,
                                        endDate,
                                      }}
                                      onEvent={handleEvent}
                                      onCallback={handleCallback}
                                      onApply={Apply}
                                    >
                                      <input
                                        class="form-control"
                                        type="text"
                                        placeholder="Select Date range"
                                      />
                                    </DateRangePicker>
                                    {/* <LocalizationProvider dateAdapter={AdapterDateFns}> */}
                                    {/* <DateRangePicker
                                    startText="Start-Date"
                                    endText="End-Date"
                                    value={value}
                                    onChange={(newValue) => {
                                      setValue(newValue);
                                    }}
                                    renderInput={(startProps, endProps) => (
                                      <React.Fragment>
                                        <TextField {...startProps} />
                                        <Box sx={{ mx: 2 }} {...endProps}> to </Box> 
                                        <TextField {...endProps} disabled/>
                                      </React.Fragment>
                                    )}
                                  />
                                   */}
                                    {/* </LocalizationProvider>  */}
                                  </Col>
                                  <Col
                                    style={
                                      showDatePicker
                                        ? {
                                            backgroundColor: 'rgb(248 248 248)',
                                          }
                                        : null
                                    }
                                    className="d-flex col-md-4 col-sm-12 mb-2"
                                  >
                                    {' '}
                                    <button
                                      type="button"
                                      className="btn w-lg"
                                      style={{
                                        backgroundColor: '#0b3629',
                                        color: 'white',
                                      }}
                                      onClick={() =>
                                        chkFunc(startDate, endDate)
                                      }
                                      // disabled={value[1] !== null ? false : true}
                                    >
                                      Search from Date
                                    </button>
                                    <button
                                      type="button"
                                      className="btn"
                                      style={{
                                        backgroundColor: '#0b3629',
                                        color: 'white',
                                        marginLeft: '5px',
                                      }}
                                      onClick={() => props.Refresh(true)}
                                      // disabled={value[1] !== null ? false : true}
                                    >
                                      <i class="fas fa-sync"></i>
                                      &nbsp; Refresh
                                    </button>
                                    <i className="refresh"></i>
                                    <i
                                      class="fa fa-refresh"
                                      aria-hidden="true"
                                    ></i>
                                    {/* <span data-icon="el:refresh"></span> */}
                                  </Col>
                                </>
                              ) : (
                                <Col className="col-md-6">
                                  <Button
                                    onClick={() => {
                                      props.loadModalTrue()
                                    }}
                                    style={{
                                      backgroundColor: '#0b3629',
                                      color: 'white',
                                    }}
                                    className="font-16 btn-block mb-2 w-lg"
                                  >
                                    <i className="mdi mdi-plus-circle-outline me-1" />
                                    {props.buttonValue}
                                  </Button>
                                </Col>
                              )}

                              {/* {paginationProps.page < 2 ? */}
                              <Col className="col-md-4 text-end">
                                <div className="search-box me-2 d-inline-block">
                                  <div className="position-relative">
                                    <SearchBar {...toolkitProps.searchProps} />
                                    <i className="bx bx-search-alt search-icon" />
                                  </div>
                                </div>
                              </Col>
                              {/* //  : null} */}
                            </Row>
                          )}
                          <Row>
                            <Col xl="12">
                              <div className="table-responsive">
                                <BootstrapTable
                                  bootstrap4
                                  keyField={keyField}
                                  responsive
                                  bordered={false}
                                  // striped={false}
                                  defaultSorted={defaultSorted}
                                  // selectRow={false}
                                  noDataIndication={emptyDataMessage}
                                  classes={'table align-middle table-nowrap'}
                                  headerWrapperClasses={'thead-light'}
                                  {...toolkitProps.baseProps}
                                  pagination={paginationFactory()}
                                  {...paginationTableProps}
                                  ref={node}
                                  striped
                                  condensed
                                  {...props.baseProps}
                                />
                              </div>
                            </Col>
                          </Row>
                          <Row className="align-items-md-center mt-30">
                            <Col className="inner-custom-pagination d-flex">
                              <div className="d-inline">
                                <SizePerPageDropdownStandalone
                                  {...paginationProps}
                                />
                              </div>
                              <div className="text-md-right ms-auto">
                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                              </div>
                            </Col>
                          </Row>
                        </React.Fragment>
                      )}
                    </ToolkitProvider>
                  )}
                </PaginationProvider>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  )
}

export default memo(DatatableTables, areEqual)
