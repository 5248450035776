// let token =localStorage.getItem("access_token")
let token = localStorage.getItem('access_token')
export default {
  localStorage: {
    access_token: 'access_token',
    localToken: token,
  },
  loginUrl: {
    logninPage:
      process.env.REACT_APP_REDIRECT_URL || 'https://usf.softoo-dev.com/login',
  },
  powerBiUrl: {
    url:
      process.env.REACT_APP_POWERBI_URL ||
      'https://app.powerbi.com/reportEmbed?reportId=d6a6eb12-8547-4933-a7fa-0ca032e1a5d4&autoAuth=true&ctid=1056d58d-37d4-4f3f-8f2e-e45a6e897eb5',
    // url:"https://app.powerbi.com/view?r=eyJrIjoiNzc3NDAwNjktOTI5OC00ODMxLTk0N2MtN2Q5Njk3NGZmZDk1IiwidCI6ImNlYjlmMjY0LWQwZmYtNDQ3My05MTVmLTZhZDMyNzEyOTI0MyIsImMiOjl9"
  },
}
