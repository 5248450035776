import React, { useState, useEffect } from 'react'
import MetaTags from "react-meta-tags";
import Breadcrumb from './common/breadCrumb';
import DatatableTables from './Table/dataTables';
import { Link } from "react-router-dom";
import Select from "react-select";
import { convertToObj, convertStringToObj, apiCall } from './common/helpers';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import {
  Container,
  Row,
  Col,
  ModalHeader,
  ModalBody,
  Modal,
  Label} from "reactstrap";
const saveObj = {
  name: '',
  category: '',
  operator: ''
}
let specialChar =null;
function Projects() {
  const [modal, setmodal] = useState(false);
  const [editModal, seteditModal] = useState(false)
  const [selectedGroup, setselectedGroup] = useState(null);
  const [nameLength, setnameLength] = useState(false)
  const [selectedGroupCategory, setselectedGroupCategory] = useState(null);
  const [operators, setoperators] = useState(null)
  const [categories, setcategories] = useState(null)
  const [saveModal, setsaveModal] = useState(saveObj)
  const [projectsData, setprojectsData] = useState(null)
  const [loaderSet, setloaderSet] = useState(false)
  function handleSelectGroupOperator(selectedGroup) {
    let value = selectedGroup.value
    setselectedGroup(selectedGroup);
    setsaveModal(saveObj => ({ ...saveObj, operator: value }))
  }
  function handleSelectGroupCategory(selectedGroupCategory) {
    let value = selectedGroupCategory.value
    setselectedGroupCategory(selectedGroupCategory);
    setsaveModal(saveObj => ({ ...saveObj, category: value }))
  }
  useEffect(async () => {
     setloaderSet(true)
    let projectsData = await apiCall('GET', "project")
    setprojectsData(projectsData.projects);
    setloaderSet(false)
    let catData = await apiCall('GET', "project/categories")
    setcategories(catData.projectCategories);
    let opData = await apiCall('GET', "operators")
    setoperators(opData.operators);
  }, [])
  if (operators) {
    var data = convertToObj(operators);
    var catData = convertToObj(categories)
  }
  const onEditClick = (row) => {
    setsaveModal(row)
    let opData = convertStringToObj(row.operator)
    let catData = convertStringToObj(row.category)
    setselectedGroupCategory(catData)
    setselectedGroup(opData)
    setsaveModal(row)
    setmodal(!modal);
    seteditModal(true)
    setnameLength(false)
}
  const createProject = async () => {
     if(saveModal.name.length >1 && specialChar==null ){
       await setloaderSet(true)
    let method = editModal ? "PUT" : "POST";
    let response = await apiCall(method, "project",saveModal,true)
    setloaderSet(false)
    setmodal(!modal);
   }else{
     if(saveModal.name.length <1){setnameLength(true)}
    }
    let projectsData = await apiCall('GET', "project")
    setprojectsData(projectsData.projects);
 
  }
  const clearProject = () => {
    setsaveModal(saveObj)
    setselectedGroup(null)
    setselectedGroupCategory(null)
    specialChar=null
  }
  const onHandleChange = (e) => {
    let value=e.target.value;
    setsaveModal(saveObj => ({ ...saveObj, [e.target.name]: e.target.value }))
    if(value.length >1){
      setnameLength(false)
    }
    else{
      setnameLength(true)
    }
 validCheck(value)
  }
  const validCheck=(value)=>{
    if(value){
      const regex = /^[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]*$/;
      specialChar= value.match(regex);
   }
  }
  const loadModalTrue =()=>{
    setmodal(!modal);
            clearProject();
            seteditModal(false)
            setnameLength(false)
  }
  return (
   <>
    <Backdrop
        sx={{ color: '#b4e0b4',zIndex:'9999'}}
        open={loaderSet}
        invisible={!loaderSet}
       >
         <CircularProgress color="inherit" />Loading...
        </Backdrop>
    <div className="page-content">
      <MetaTags>
        <title>Projects | USF</title>
      </MetaTags>
      <Container fluid>
        {/* Render Breadcrumb*/}
        <Breadcrumb
          title="Project"
          breadcrumbItem="Projects"
        />
        <Row>
        </Row>
      </Container>
     {/* <div className="text-sm-end">
        <Button
          onClick={() => {
            setmodal(!modal);
            clearProject();
            seteditModal(false)
            setnameLength(false)
          }}
          style={{backgroundColor:"#0b3629",color:"white"}}
          className="font-16 btn-block mb-2 w-lg"
         >
           <i className="mdi mdi-plus-circle-outline me-1" />
          Create Project
        </Button>
      </div> */}
      <Modal
        size="lg"
        isOpen={modal}
        toggle={() => {
          setmodal(!modal);
        }}
       >
        <ModalHeader
          toggle={() => {
            setmodal(!modal);
          }}
        >
        {editModal ? "Update Project" : "Create Project"}
        </ModalHeader>
        <ModalBody>
        <form>
            <Row>
              <Col lg={12}>
                <div className="mb-3">
                  <label htmlFor="name">Name</label>{!saveModal.name ? <span class="text-danger">*</span> :null }
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    required={require}
                    placeholder="Enter Name"
                    value={saveModal.name}
                    maxlength={45}
                    name="name"
                    onChange={(e) => {
                      onHandleChange(e);
                    }}
                  />
                {nameLength ? <span class="text-danger">Name length too short</span>: specialChar? <span class="text-danger">Special characters not allowed</span> :null}
                </div>
              </Col>
              <div className="mb-3">
                <Label>Categories</Label>{saveModal.category ? null : <span class="text-danger">*</span>}
                <Select
                  value={selectedGroupCategory}
                  onChange={(e) => handleSelectGroupCategory(e)}
                  options={catData}
                  classNamePrefix="select2-selection"
                />
              </div>
              <div className="mb-3">
                <Label>Operators</Label>{saveModal.operator ? null : <span class="text-danger">*</span>}
                <Select
                  value={selectedGroup}
                  onChange={(e) => handleSelectGroupOperator(e)}
                  options={data}
                  classNamePrefix="select2-selection"
                />
               </div>
            </Row>
          </form>
          <Row className='text-end'>
            <Col lg={12} >

              {editModal ? null : <button className="btn btn-danger" style={{marginRight:"5px"}} onClick={clearProject}>
                Clear
              </button>}
              <button className="btn " style={{backgroundColor:"#0b3629",color:"white"}} onClick={() => createProject()} disabled={(saveModal.operator && saveModal.name) && (saveModal.category)? false : true}>
            {editModal ? "Update" : "Submit"}
              </button>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
      <div
      style={{
        width: "100%",
        height: "100",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
      }}
    >
  </div>
      <DatatableTables DataTable={projectsData} onEditClick={onEditClick} name="Projects" loadModalTrue={loadModalTrue} buttonValue="Create Project"/>
   
    </div>
    </>
  )
}
export default Projects;