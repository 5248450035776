import React, { useEffect ,useState}  from 'react'
import MetaTags from "react-meta-tags";
import {
  Container,
  Row,
} from "reactstrap";
import Breadcrumb from './common/breadCrumb';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import keys from './common/keys';
import axios from 'axios';
import Box from "@mui/material/Box";
function Dashboard(props) {
  const API_URL = process.env.REACT_APP_API_URL || 'https://api-analytics.usf.softoo-dev.com/';
  const urlDashboardOld = process.env.REACT_APP_POWERBI_OLD_URL || 'https://app.powerbi.com/view?r=eyJrIjoiN2YxYjIyODYtZjJjOS00YmZmLWI5ZWUtOTEyOTQ3ZjVmZWYwIiwidCI6IjEwNTZkNThkLTM3ZDQtNGYzZi04ZjJlLWU0NWE2ZTg5N2ViNSIsImMiOjl9';
  const [urlDashboard, seturlDashboard] = useState('');
  const [loaderSet, setloaderSet] = useState(false)
  const [value, setValue] = useState(1);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  let searchParams = props?.location?.search;
  if(searchParams){
    let url = searchParams.slice(7);
    localStorage.setItem(keys.localStorage.access_token, url);
    sessionStorage.setItem(keys.localStorage.access_token, url);
  }
  useEffect(async () => {
  setloaderSet(true)
   let token =sessionStorage.getItem(keys.localStorage.access_token)
    const options2 = {
      method: 'GET',
      url: API_URL + 'dashboard',
      headers: { 'Content-Type': 'application/json', Authorization: 'Bearer'+" "+token}
    };
axios.request(options2).then(function (response) {
        seturlDashboard(response.data.data.dashboardUrl);
  }).catch(function (error) {
  
      });
  setloaderSet(false)
}, [])
  return (
    <>
      <Backdrop
        sx={{ color: '#b4e0b4',zIndex:'9999'}}
        open={loaderSet}
        invisible={!loaderSet}
      >
         <CircularProgress color="inherit" />Loading...
      </Backdrop>
      <div className="page-content">
        <MetaTags>
          <title>Dashboard | USF Dashboard</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumb
             title="Dashboards"
            breadcrumbItem="Dashboard"
          />

          <Row>
              </Row>
              </Container>

        <TabContext value={`${value}`}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <Tab label="New Report" value="1" />
            <Tab label="Old Report" value="2" />
          </TabList>
          <Box sx={{ height: "100vh", p:0 }}>
            <TabPanel value="1" sx={{p:0, pt:2 }}>
              <iframe
                src={urlDashboard}
                style={{ width: "100%", height: "750px" }}
              />
            </TabPanel>
            
            <TabPanel value="2" sx={{p:0, pt:2}}>
              <iframe 
                src={urlDashboardOld} 
                style={{ width: "100%", height: "750px" }}
              />
            </TabPanel>
          </Box>
        </TabContext>
      </div>
    </>
    )
}
export default Dashboard;