import React, { useState, useEffect } from 'react'
import DatatableTables from './Table/dataTables';
import MetaTags from "react-meta-tags";
import Breadcrumb from './common/breadCrumb';
import { apiCall } from './common/helpers';
import { addDays } from 'date-fns';
import axios from 'axios'; 
import keys from './common/keys';
import { Row, Col, Card, CardBody,Modal,ModalHeader,ModalBody, CardTitle, Button } from "reactstrap";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import {
    Container,
  } from "reactstrap";
 function Files() {
     const [filesData, setfilesData] = useState(null)
     const [messageData, setmessageData] = useState(null);
     const [modal, setmodal] = useState(false);
     const [state, setState] = useState([null,null]);
     const [loaderSet, setloaderSet] = useState(false);
      const datePicker =async (value1,value2)=>{
        setloaderSet(true)
      let sDate=value1.toISOString()
        let eDate=value2.toISOString()
        let ssDate=sDate.slice(0,11)+"00:00:00:00";
        // let eeDate=eDate.slice(0,11)+"00:00:00:00";;
       let objModal={
          startDate: ssDate, 
          endDate: eDate
        }
         let response = await apiCall('GET', "organization/file",null,true,objModal)
         setfilesData(response.files)
         setloaderSet(false)
      }
      const openMessageModal =(message)=>{
        setmessageData(message)
        setmodal(true)
      }
    useEffect(async() => {
      setloaderSet(true)
      let filesData = await apiCall('GET', "organization/file")
      setfilesData(filesData.files);
      setloaderSet(false)
      }, [])
    const  Refresh =async(button)=>{
     
     if(button){ setloaderSet(true)}
      let filesData = await apiCall('GET', "organization/file")
      setfilesData(filesData.files);
     if(button){ setloaderSet(false)}
      }
    return (
      <>
       <Backdrop
        sx={{ color: '#b4e0b4',zIndex:'9999'}}
        open={loaderSet}
        invisible={!loaderSet}
       >
         <CircularProgress color="inherit" />Loading...
        </Backdrop>
      
        <div className="page-content">
        <MetaTags>
       <title>Files | USF</title>
     </MetaTags>
     <Container fluid>
       {/* Render Breadcrumb */}
       <Breadcrumb
          title="Files"
         breadcrumbItem="Files"
       />
        </Container>
        <Modal
                size="lg"
                isOpen={modal}
                toggle={() => {
                  setmodal(!modal);
                }}
                //  style={{backgroundColor:"none"}}
                backdropClassName="false"
              
              >
                <ModalHeader
                  toggle={() => {
                    setmodal(!modal);
                    // clearFiles()
                  }}
                   >
                  Message
                </ModalHeader>
                <ModalBody style={{backgroundColor:"#86a385"}}>
                  <Row style={{margin:"10px"}}>
                  <textarea
                    style={{
                      whiteSpace: "normal",
                      resize: "none",
                    }}
                     rows={20}
                    value={messageData}
                  ></textarea>
                  </Row>
                 </ModalBody>
              </Modal>
   <DatatableTables name="Files" DataTable={filesData} files={true} state={state} datePicker={datePicker} Refresh={Refresh} openMessageModal={openMessageModal}/>
     </div>
     </>
    )
}
export default Files;