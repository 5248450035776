import axios from "axios";
import keys from "./keys";
import { toast, toastify } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const API_URL = process.env.REACT_APP_API_URL || "https://api-analytics.usf.softoo-dev.com/";
const token=keys.localStorage.localToken
const axiosApi = axios.create({
  baseURL: API_URL,
});
const convertToObj =(list)=>{
    if(list){
    let listOptions=null;
  return  listOptions = list && list.map((item) => ({
        label: item,
        value: item,
      }));
    }
}
const convertStringToObj =(data)=>{
return {
    label:data,
    value:data,
}
}
const toastifyHelper = (type,message) => {
 toast.configure();
  switch(type) {
    case "error":
      toast.error(message, { position: toast.POSITION.TOP_RIGHT ,theme:"colored"})
      break;
    case "success":
      toast.success(message, { position: toast.POSITION.TOP_RIGHT })
      break;
  }
  // toast.error("Something went wrong", { position: toast.POSITION.TOP_RIGHT })
  // toast.success("Saved Successfully", { position: toast.POSITION.TOP_RIGHT })
  // toast.success("Saved Successfully", { position: toast.POSITION.TOP_RIGHT })
  // toast.success("Saved Successfully", { position: toast.POSITION.TOP_RIGHT })
  // toast.success("Saved Successfully", { position: toast.POSITION.TOP_RIGHT })
}
 const apiCall = async(method,url,obj,permit,params)=>{
   if(!token){
  var newToken =localStorage.getItem("access_token")
}
 let options = {
        method: method,
       url: API_URL + url,
        headers: {'Content-Type': 'application/json', Authorization: 'Bearer'+" "+(token ? token : newToken)},
        params:params ? params :null,
        data: obj ? obj : null
      };

  return  await axios.request(options).then(function (response) {
    let data=  response.data.data;
   if( permit ){toastifyHelper("success",response.data.message)}
    return data
     }).catch(function (error) {
        if(error.response.status == 401 || error.response.status == 403){
          window.open(keys.loginUrl.logninPage, "_self");
       }
        toastifyHelper("error",error.response.data.message || "something went wrong")
      });
}
export {
    convertToObj,
    apiCall,
    convertStringToObj,
    toastifyHelper
}

